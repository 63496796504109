@tailwind base;
@tailwind components;
@tailwind utilities;

.navbarItem{
    @apply flex items-center text-black dark:text-white gap-0.5;
}

.navbarLink{
    @apply px-4 py-2 text-center lg:p-0;
}
.navbarLink.active {
    color: #eab308 !important; /* Set red color for active links */
}

.navbarCTA {
    @apply px-4 py-2 text-center text-white transition-all lg:rounded-lg h-fit hover:bg-opacity-50;
    background-color: #eab308;
}

/* NAVBAR END*/

.title{
     @apply text-3xl font-bold tracking-wide lg:text-5xl;
    color: #eab308;
	}
	
.titlle{
     @apply text-3xl font-bold tracking-wide lg:text-5xl;
    color: #eab308;
	}


.smallTitle{
    @apply text-xl font-bold tracking-wide lg:text-3xl;
	color: #eab308;
}

.subtitle{
    @apply text-lg font-semibold tracking-wide text-black lg:text-2xl dark:text-white;
}
.subsubtitle{
    @apply text-base font-semibold tracking-wide text-white lg:text-lg;
}

.CTA{
       @apply px-5 py-3 text-lg font-bold tracking-wide text-white transition-all lg:text-xl rounded-xl h-fit w-fit hover:bg-opacity-70;
    background-color: #eab308;
	}

/* CARDS */
.cardContainer{
    @apply relative h-48 transition-all bg-cover shadow-lg cursor-pointer max-w-[28rem] -z-10 lg:h-60 rounded-xl hover:shadow-2xl;
}

.cardFilter{
    @apply absolute inset-0 transition-all bg-black bg-opacity-50 rounded-xl group-hover:opacity-75;
}

.cardTitle{
    @apply text-lg font-semibold tracking-wide text-white lg:text-2xl;
}
/* CARDS END */

/* TABLE */
.tableHead{
    @apply text-xs text-black uppercase bg-gray-200 dark:bg-gray-700 dark:text-white;
}

.tableRow{
    @apply border-b odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800 dark:border-gray-700;
}

.tableDataCell{
 @apply px-6 py-4 font-medium text-center text-gray-900 dark:text-white;
}
/* TABLE END*/

/* Accordion */
.accordionSubtitle{
    @apply mt-2 font-bold text-gray-700 dark:text-gray-300;
}

.accordionItem{
    @apply ml-2 text-gray-700 dark:text-gray-300;
}
/* Accordion End*/

.inputField{
    @apply bg-gray-200 border rounded-lg lg:w-96 focus:outline-none focus:ring focus:border-blue-800 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white;
}

/* GALLERY */
.galleryContainer{
    @apply grid grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2
}

.galleryImage{
    @apply object-contain w-full select-none;
}
/* GALLERY END*/